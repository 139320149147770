import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { messageError } from '../share/AlertModal';
import * as EmailValidator from 'email-validator';
import { AuthControllerApi, OtpControllerApi } from '@digital-signer-gw/digital-signer-gw-ts-react';
import { Spin } from 'antd';
import {RequestLoginOTP, loginByOTP, loginByOTPViewerToken} from '../../slice/authSlice';
import { useAppDispatch } from '../../store';
const OtpForm = () => {
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const viewerToken = localStorage.getItem("viewerToken") as string;
  const [email, setEmail] = useState<string>('');
  const inputRefs = useRef<any>([]);
  const [seconds, setSeconds] = useState(60);
  const [disableSendEmailButton, setDisableSendEmailButton] = useState(true)
  const [showPage, setShowPage] = useState(false)
  const [showEmailForm, setShowEmailForm] = useState(false)
  const [refCode, setRefCode] = useState<any | string>(null);
  const navigate = useNavigate();
  const otpControllerApi: OtpControllerApi = new OtpControllerApi();
  const authControllerApi: AuthControllerApi = new AuthControllerApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [spinning, setSpinning] = useState<boolean>(false);
  const idToken = localStorage.getItem('IdToken') as any;
  useEffect(() => {
    localStorage.removeItem('ds_jwt_token');
    if (idToken) {
      if(localStorage.getItem('link_type') === "EXTERNAL"){
        if (viewerToken !== null) {
          setShowPage(true)
          setShowEmailForm(true)
        } else {
          setShowEmailForm(false)
          requestOtp()
        }
      }else{
        navigate('/login');
      }
      
    } else {
      messageError("No Id Token");
    }

  }, [idToken])

  useEffect(() => {
    const timer: any =
      seconds > 0 &&
      setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  const requestOtp = () => {
    setSpinning(true);
    otpControllerApi.requestOtpUsingPOST(idToken).then((response:any) => {
      setRefCode(response?.data?.data?.refCode);
      setEmail((response?.data?.data as any)?.email)
      setSeconds(60);
      setShowPage(true);
      setSpinning(false);
    }).catch(e => {
      setSpinning(false);
      messageError(e)
    })
  }

  const requestOtpByEmail = (email: string) => {
    setSpinning(true);
    otpControllerApi.requestOtpLinkViewerUsingPOST(email, viewerToken).then((response:any) => {
      setRefCode(response?.data?.data?.refCode);
      setEmail((response?.data?.data as any)?.email)
      setSeconds(60);
      setSpinning(false);
    }).catch(e => {
      setSpinning(false);
      messageError(e)
    })
  }

  const handleOTPChange = (e: any, index: number) => {
    const value = e.target.value;
    setOTP((prevOTP) => {
      const newOTP = [...prevOTP];
      newOTP[index] = value;
      return newOTP;
    });
    // Move focus to the next input field if available
    if (value !== '' && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSetEmail = () => {
    setShowEmailForm(false)
    requestOtpByEmail(email);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    const valid = EmailValidator.validate(e.target.value);
    setDisableSendEmailButton(!valid);
  }

  const handleVerifyOTPSubmit = () => {
    if (seconds > 0) {
      const enteredOTP = otp.join(''); // Join OTP array to form the complete OTP
      setSpinning(true);
      let verify;
      let body: RequestLoginOTP = {
        enterOTP: enteredOTP,
        idToken: idToken
      }
      if (viewerToken !== null) {
        body = {
          enterOTP: enteredOTP,
          idToken: viewerToken
        }
        verify = dispatch(loginByOTPViewerToken(body));
      } else {
        verify = dispatch(loginByOTP(body));
      }
      verify.unwrap()
          .then(response => {
            let token = response.data?.token as any
            localStorage.setItem("isAdmin", "false");
            setSpinning(false);
            dispatch({type: "token/setToken", payload: token});
            getUserInfo();
            navigate("/digitalSignature");
          }).catch((e: any) => {
        setOTP(['', '', '', '', '', ''])
        setSpinning(false);
        if (e.name === '401') {
          messageError('Incorrect OTP')
        } else {
          messageError(e)
        }

      });
    } else {
      messageError("OTP expired, Please resend OTP.")
    }

  };

  const getUserInfo = () => {
    setSpinning(true)
    authControllerApi.getUserInfoByTokenUsingPOST(idToken).then((response: any) => {
      setSpinning(false)
      dispatch({type: "userProfile/setProfle", payload: response.data});
    }).catch(e => {
      setSpinning(false)
    });
  }

  const goBackToLogin = ()  => {
    navigate('/login')
  }

  return (
    <BoxContainer>
      {showPage && (
          <Container>

            {/* <Title><img src={logo.default} alt="Logo" className='logo'  style={{ width: '50px', height: 'auto' }} />SET Digital signature</Title>
  <br /> */}
            {showEmailForm && (
                <>
                  <Title>กรุณากรอกอีเมลสำหรับส่ง OTP</Title>
                  <p>ระบบจะทำการส่งเลข OTP ไปยังอีเมลที่ระบุ </p>
                  <input type="text" className='form-control col-12 mb-4 mx-auto' placeholder='example@mail.com'
                         value={email}
                         style={{minWidth: 150}}
                         onChange={handleEmailChange} required/>

                  <button
                      type='button'
                      disabled={disableSendEmailButton}
                      onClick={handleSetEmail}
                      style={{minWidth: 150}}
                      className="btn btn-primary text-white"

                  >Send OTP
                  </button>
                </>
            )}

            {!showEmailForm && (
                <>
                <Title>กรุณากรอกเลข OTP</Title>
                  <span>ระบบทำการส่งเลข OTP Ref.Code: {refCode} ไปที่เมล {email} </span>
                  <br/>
                  <OTPContainer>
                    {otp.map((digit, index) => (
                        <OTPInput
                            className='form-control'
                            key={index}
                            type="text"
                            value={digit}
                            onChange={(e) => handleOTPChange(e, index)}
                            maxLength={1}
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    ))}
                  </OTPContainer>
                  <br/>
                  <div>จะส่งOTPอีกครั้งได้ภายใน {seconds} วินาที <button className='btn btn-link' onClick={requestOtp}
                                                                         disabled={seconds != 0}>ส่ง OTP</button></div>
                  <br/>
                  <button
                      type='button'
                      onClick={handleVerifyOTPSubmit}
                      style={{minWidth: 150}}
                      className="btn btn-primary text-white"

                  >Verify
                  </button>
                </>
            )}

          </Container>
      )}
      <Spin spinning={spinning} fullscreen/>
    </BoxContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const BoxContainer = styled.div`
margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
`

const Title = styled.h1`
        font-size: 24px;
        margin-bottom: 20px;
        `;

const OTPContainer = styled.div`
        display: flex;
        justify-content: center;
        `;

const OTPInput = styled.input`
        width: 40px;
        height: 40px;
        font-size: 20px;
        text-align: center;
        padding: 0.375rem 0.5rem !important;
        margin: 0 5px;
        border-color: #CED4DA;
        `;

export default OtpForm;

