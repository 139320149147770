import React, { useEffect, useState } from 'react'
import { CardBox, CardContainer } from '../asset/style'
import * as loginBannerSET from '../asset/image/loginBannerSET.png'
import * as logo from "../asset/image/icon-set-logo.png";
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import { RequestLoginOTP, loginByKeyCloak, loginByOTP } from '../slice/authSlice';
import { useAppDispatch } from '../store';
import { messageError } from './share/AlertModal';
import { AuthBodyRequest, AuthControllerApi, OtpControllerApi } from '@digital-signer-gw/digital-signer-gw-ts-react';
const authControllerApi: AuthControllerApi = new AuthControllerApi();
type Props = {}
export const LoginContainer = styled.div`
    margin: 0;
    padding: 0;
    display: block;
    align-items: center;
    border-radius: 0px 46px 46px 0px;
    background-color: white;
    width: 400px;
`
const Login = (props: Props) => {
    const navigate = useNavigate();
    const [loginByEmail, setLoginByEmail] = useState(false);
    const [email, setEmail] = useState();
    const [spinning, setSpinning] = useState<boolean>(false);
    const idToken = localStorage.getItem('IdToken') as any;
    const otpControllerApi: OtpControllerApi = new OtpControllerApi();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code') ?? undefined;
    const sessionState =  searchParams.get('sessionState') ?? undefined;
    const dispatch = useAppDispatch();
    useEffect(()=>{
        localStorage.removeItem('ds_jwt_token');
        if(localStorage.getItem('link_type') === "EXTERNAL"){
            navigate('/otp');
        }else{
            if(searchParams.get('code')){
                logInByKeycloack();
            }
        }
        
    },[code])
    const requestOtp = () => {
        setSpinning(true);
        otpControllerApi.requestOtpUsingPOST(idToken).then((response) => {
            setSpinning(false);
        }).catch(e => {
            setSpinning(false);
            messageError(e.message)
        })
    }
    const handleLoginAzureAd = () => {
        window.location.replace(`${process.env.REACT_APP_KEYCLOAK_URL}?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&redirect_uri=${encodeURI(process.env.REACT_APP_KEYCLOAK_REDIRECT_URI as string)}&scope=openid&response_type=code&response_mode=query`)
    }
    const logInByKeycloack = () => {
        setSpinning(true);
        let body:AuthBodyRequest  = {
            code: code as any,
            redirectUrl: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI
        }
        dispatch(loginByKeyCloak(body)).unwrap()
            .then(response => {
                let token = response.data?.token as any
                localStorage.setItem("isAdmin", "true");
                setSpinning(false);
                
                dispatch({ type: "token/setToken", payload: token });
                getUserInfo();
                navigate("/digitalSignature");
            }).catch((e: TypeError) => {
                setSpinning(false);
                messageError(e)
            });
    }
    const changeLoginWithEmail = () => {
        setEmail(undefined);
        setLoginByEmail(true);
        requestOtp()
        
    }
    const loginWithOTP = () => {
        navigate("/otp");
    }

    const changeLoginWithAzure = () => {
        setEmail(undefined);
        setLoginByEmail(false);
    }
    const handleLoginByEmail = (e: any) => {
        e.preventDefault();
        setSpinning(true);
        let body: RequestLoginOTP = {
            enterOTP: email as any,
            idToken: idToken
        }
        dispatch(loginByOTP(body)).unwrap()
            .then(response => {
                let token = response.data?.token as any
                localStorage.setItem("isAdmin", "false");
                setSpinning(false);
                dispatch({ type: "token/setToken", payload: token });
                getUserInfo();
                navigate("/digitalSignature");
            }).catch((e: TypeError) => {
                setSpinning(false);
                messageError(e)
            });

    }
    const getUserInfo = () => {
        setSpinning(true)
        authControllerApi.getUserInfoByTokenUsingPOST(idToken).then((response: any) => {
            setSpinning(false)
            dispatch({ type: "userProfile/setProfle", payload: response.data });
        }).catch(e => {
            setSpinning(false)
        });
    }
    return (
        <CardContainer>
            <div className='d-flex flex-wrap'>
                <div className='col-lg-6 col-md-6 col-sm-12'><img src={loginBannerSET.default} style={{ width: '400px', height: 'auto' }} /></div>
                <div className='d-flex align-items-stretch col-lg-6 col-md-6 col-sm-12'>
                    <LoginContainer>

                        <div className='d-flex flex-column col-12'>
                            <br />
                            <div className='mx-auto'><img src={logo.default} alt="Logo" className="logo" style={{ width: 'auto', height: '8vh' }} /></div>
                            <h5 className='mx-auto' >Digital Signature</h5>
                            <br />

                            {!loginByEmail && <>
                                <br />
                                <button className='btn btn-primary text-white mx-auto col-10' onClick={() => { handleLoginAzureAd() }}>Login with SET user</button>
                                {/* <button className='btn mx-auto' onClick={loginWithOTP}>Login ด้วย OTP</button> */}
                            </>}
                            {loginByEmail && <div className='ms-3 me-3'>
                                <form className='d-flex flex-column' onSubmit={(e) => handleLoginByEmail(e)}>
                                    <input type="text" className='form-control col-12 mb-2 mx-auto' placeholder='example@mail.com' value={email} onChange={(e) => setEmail(e.target.value as any)} required />
                                    <button className='btn btn-primary text-white mx-auto col-10' type='submit'>Login with Email</button>
                                    <button className='btn mx-auto' onClick={changeLoginWithAzure}>Login ด้วย Azure</button>

                                </form>
                            </div>}
                        </div>

                    </LoginContainer></div>
            </div>
            <Spin spinning={spinning} fullscreen />
        </CardContainer>
    )
}

export default Login