import React from 'react'
import { FlexCenter, SidebarWrapper } from '../../asset/style'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

type Props = {}
const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const MenuItem = styled.div`
  //padding: 10px;
  background-color: white;
  min-width: 200px;
  /* border: 1px solid #C8C8C8; */
  margin-bottom: 8px;
  &:hover{
    background-color: #ffffff5e;
  }
`;

const MenuLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  padding: 10px;
  background-color: white;
  min-width: 200px;
  border: 1px solid #C8C8C8;
  margin-bottom: 8px;
  cursor: pointer;
  color:black;
  &:hover{
    color: #fcb034;
    background-color: #ffffff5e;
  }

  &.active {
    /* color: #fcb034; */
  }
`;
const SideBar = (props: Props) => {
  return (
    <SidebarWrapper>
            {/* <FlexCenter>
                <img src="/images/SET_Logo.png" alt="SET_Logo.png" width={100} />
            </FlexCenter>
            <FlexCenter><h4 className='mt-2'>Digital Signature</h4></FlexCenter> */}
            <Menu>
                <MenuItem>
                    <MenuLink to="/main" className="link">
                        <img src="/images/add_alert.png" alt="" width={30} className='me-2' />
                        <span>Home</span>
                    </MenuLink>
                </MenuItem>
            </Menu>
        </SidebarWrapper>
  )
}

export default SideBar