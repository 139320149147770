import { Button, Layout, Result, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react'
import { useNavigate } from 'react-router-dom';
// import setIcon from '../../public/images/SETLogo.png';

function ExceptionalPage() {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate('/login');
  }
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Content style={{ padding: '0 50px', marginTop: 150 }}>
          {/* <Row align='middle'>
            <img src='/images/SETLogo.png' style={{ width: 200, height: 'auto' }} />
            <h3>Sorry, you are not authorized to access this page.</h3>
            <Button onClick={goToLogin} type="primary">Go to Login</Button>
          </Row> */}

          <Result
            title="401"
            icon={<img src='/images/SETLogo.png' style={{ width: 200, height: 'auto' }} />}
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Button onClick={goToLogin} type="primary">Go to Login</Button>}
          />
        </Content>
      </Layout>
    </>

  )
}

export default ExceptionalPage;