import { configureStore } from '@reduxjs/toolkit'
import tokenReducer from './slice/tokenSlice'
import idTokenReducer from './slice/idTokenSlice'
import authReducer from './slice/authSlice'
import userReducer from './slice/userProfileSlice'
import { useDispatch } from 'react-redux';


export const store = configureStore({
  reducer: {
    token: tokenReducer,
    idToken: idTokenReducer,
    authReducer,
    userProfileReducer: userReducer
  },
  devTools: process.env.NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();