import React, { useEffect } from 'react'
import * as logo from "../asset/image/icon-set-logo.png";
import styled from 'styled-components';
import { CardBox, CardContainer } from '../asset/style';
type Props = {}

const Thankyou = (props: Props) => {
    useEffect(() => {
        sessionStorage.removeItem("statePage");
        sessionStorage.removeItem("IdToken");
        sessionStorage.removeItem("isAdmin");
        sessionStorage.removeItem("ds_jwt_token");
    }, [])
    return (
        <CardContainer>
            <CardBox>
                <div className='text-center'>
                    <img src={logo.default} alt="Logo" className="logo" style={{ width: '250px', height: 'auto' }} />
                    <br />
                    <h4>Thank you</h4>
                </div>

            </CardBox>
        </CardContainer>
    )
}

export default Thankyou