import React, { CSSProperties } from 'react';
import DOMPurify from 'dompurify';

interface HTMLZoneProps {
  htmlContent: string;
  className?: string;
  style?: CSSProperties;
}

const HTMLZone: React.FC<HTMLZoneProps> = ({ htmlContent, className, style }) => {
  // Sanitize HTML content using DOMPurify to prevent XSS attacks
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  return (
    <div className={className}
      style={style} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
};

export default HTMLZone;