import { Modal, message } from 'antd';
export const messageSuccess = (message: string) => {
    Modal.success({
        content: message,
    });
};

export const messageError = (message: string | any) => {
    if (typeof message === "string") {
        Modal.error({
            title: 'This is an error message',
            content: message,
        });
    } else {
        let content = message.message;
        if(content == 'Network Error'){
            content = 'Connection error. Please try again.'
        }
        console.log(message?.name)
        if(message?.name == '500'){
            content = 'Request failed, Please Contact SET Procurement'
        }
        if(message?.name == '404'){
            content = 'Link expired. Please Contact SET Procurement'
        }
        Modal.error({
            title: 'This is an error message',
            content: content,
        });
    }
    
};