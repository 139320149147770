import styled from "styled-components"

export const CardBox = styled.div`
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #D5F0F6, #f6f1d5);
    border: 1px solid #D5F0F6;
    box-shadow: 0px 14px 16px 0px #00000005;
    padding: 100px 150px 100px 150px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
`
export const CardContainer = styled.div`
    margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #f0f0f0;
`
export const SidebarWrapper = styled.div`
background-color: white;
filter: drop-shadow(1px 5px 5px #ebebeb);
  //border-right: 1px solid #C6C6C6;
  color: black;
  width: 20vw;
  height: 100vh;
  position: fixed;
  padding: calc(0.9vw + 0.9vh) calc(0.9vw + 0.9vh) calc(0.9vw + 0.9vh) calc(0.9vw + 0.9vh);
  left: 0;
`;


export const Flex = styled.div`
  display: flex;
`
export const FlexCenter = styled(Flex)`
  justify-content: center;
`
export const FlexBetween = styled(Flex)`
  justify-content: space-between;
`
export const FlexEnd = styled(Flex)`
  justify-content: end;
`
export const Content = styled.div<{readonly?:any}>`
margin-left: ${p => p.readonly ?'0px' :'20vw'}; /* Adjust this value to match the width of your sidebar */
padding: 20px 40px 20px 40px;
background-color: #f2f2f2;
overflow-y: auto; /* Make the content area scrollable */
width:  calc(100vw - ${props => props.readonly?'0px':'20vw'});
height: calc(100vh - 58px - 68px);
`;
export const Footer = styled.div`
    width: calc(100vw - 20vw);
    margin-left: 20vw; 
    background-color: white;
    padding: 10px 20px 10px 20px;
    height: 58px;
`