import { Modal } from 'antd';
import React from 'react'

type Props = {
    open: boolean;
    onCancel: () => void;
    onOk: (value:any) => void;
}

const ApproveModal = (props: Props) => {
    const { open, onCancel, onOk } = props;
  return (
    <Modal
            open={open}
            title="ยืนยันการ Approve"
            okText="Confirm"
            onCancel={onCancel}
            onOk={onOk}
        >
            
        </Modal>
  )
}

export default ApproveModal