import React, { useEffect } from "react";
import { Button, Modal, Form, Input, Radio, notification } from 'antd';
interface RejectModalProp {
    open: boolean;
    onCancel: () => void;
    onOk: (value:any) => void;
}
export default function RejectModal(props: RejectModalProp) {
    const { open, onCancel, onOk } = props;
    const [form] = Form.useForm();
    useEffect(()=>{
        form.resetFields()
    },[props.open])
    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };
    const handleSubmit = async () => {
        try {
          const values = await form.validateFields();
          return onOk(values)
        } catch (errorInfo) {
          notification.error({ message: 'Error', description: 'Please Fill required form.' });
        }
      };
    return (
        <Modal
            open={open}
            title="เหตุผลยกเลิกสัญญา"
            okText="Confirm"
            onCancel={handleCancel}
            onOk={handleSubmit}
        >
            <Form form={form} name='comment-reject'>
                <Form.Item name='rejectReason'
                    rules={[{ required: true, message: "This field is required" }]}>
                    <Input.TextArea autoSize={{minRows:4,maxRows:11}} maxLength={255} placeholder='Add text' />
                </Form.Item>
            </Form>
        </Modal>
    );
};