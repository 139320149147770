import { Button, Drawer, DrawerProps, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import styled, { css } from 'styled-components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { SignatureResponse } from '@digital-signer-gw/digital-signer-gw-ts-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as zoomIn from "../../../asset/image/material-symbols-light--zoom-in.png"
import * as zoomOut from "../../../asset/image/material-symbols-light--zoom-out.png"
import { faCaretDown, faCaretRight, faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
const ViewerContainer = styled.div`
  display: flex;
  overflow: hidden;
`;


const PdfContainer = styled.div<any>`
  width: 100% ;
  overflow-y: auto;
  height: 93vh;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f4f4f4;
  transition: width 0.3s ease;
  ${({ width }) => width && css`
   width: 75%;
  `}
`;
const ForwardedPdfContainer = React.forwardRef<any,any>((props, ref) => (
    <PdfContainer ref={ref} {...props} />
));

const PageContainer = styled.div`
  margin-bottom: 16px;
`;

const SideContainer = styled.div<{ width: boolean }>`
  width: 0% ;
  position: sticky;
  top: 0;
  height: 93vh; /* Set sidebar height to fill viewport */
  overflow-y: auto; /* Add scrollbar when content exceeds height */
  transition: width 0.3s ease;
  overflow: auto;
  background-color: #fff0cd;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 2;
  ${({ width }) => width && css`
   width: 25%;
  `}
`;
const ListItem = styled.div`
  //border: 1px solid #ccc;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const ListContent = styled.div<any>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
const NavigationButtons = styled.div`
  display: flex;
  gap: 8px;

  button {
    font-size: 16px;
    &:hover{
        background-color: rgba(0,0,0,.1);
    }
    &:disabled{
        border: none;
        color: #bababa;
    }
  }
`;
const ZoomPercentage = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
`;

type Props = {
    open: boolean
    setOpen: (value: boolean) => void
    file: any;
    fileSignatureInfo: Array<SignatureResponse> | undefined
}

const PDFView = (props: Props) => {
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [openSignPerson, setOpenSignPerson] = useState(false);
    const [openIndex, setOpenIndex] = useState(-1);
    const [scale, setScale] = useState(1.2);
    const viewerRef = useRef();
    useEffect(() => {
        const viewer: any = viewerRef.current;
        if (viewer) {
            viewer.addEventListener('scroll', handleScroll);
            handleScroll(); // Initial call to set the current page

            return () => {
                viewer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [numPages]);
    const onClose = () => {
        props.setOpen(false);
    };
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };
    const toggleOpenSignPerson = () => {
        let result = !openSignPerson;
        setOpenSignPerson(result)
    }
    const toggleItem = (index: number) => {
        setOpenIndex(prevIndex => (prevIndex === index ? -1 : index));
    };
    const handleZoomIn = () => {
        setScale((prevScale) => prevScale + 0.1);
    };

    const handleZoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.1, 0.1));
    };
    const handleScroll = () => {
        if (viewerRef.current) {
            const viewer = viewerRef.current;
            const pages = (viewer as any).querySelectorAll('.react-pdf__Page');
            let currentPageNumber = 1;

            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                const { top, bottom } = page.getBoundingClientRect();

                if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                    currentPageNumber = i + 1;
                    break;
                }
            }

            setCurrentPage(currentPageNumber);
        }
    };


    return (
        <Drawer
            placement={'bottom'}
            bodyStyle={{ padding: '0px' }}
            closable={false}
            width={'100%'}
            height={'100%'}
            onClose={onClose}
            open={props.open}
            key={'bottom'}
        >
            <div style={{ height: "7vh", display: "flex", paddingRight: 20, justifyContent: 'space-between', borderBottom: "1px solid #e9ecef" }}>
                <div style={{ width: 200 }}></div>
                <NavigationButtons className='d-flex'>
                    <ZoomPercentage>
                        <span> Page {currentPage} of {numPages} </span>
                    </ZoomPercentage>
                    <button className='btn' onClick={handleZoomIn}><img src={zoomIn.default} width={30} /></button>
                    <ZoomPercentage>{((scale - 0.2) * 100).toFixed(0)}%</ZoomPercentage>
                    <button className='btn' onClick={handleZoomOut}><img src={zoomOut.default} width={30} /></button>
                </NavigationButtons>
                <div className='my-1'>
                    <button className='btn btn-outline-secondary btn-sm me-2' onClick={onClose}>Back</button>
                    <button className='btn btn-primary btn-sm' onClick={toggleOpenSignPerson}>Signature panel</button>
                </div>

            </div>
            <ViewerContainer>
                <ForwardedPdfContainer width={openSignPerson} ref={viewerRef}>
                    <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <PageContainer key={`page-container-${index}`}>
                                <Page className='container-pdf'  pageNumber={index + 1} scale={scale} />
                            </PageContainer>
                        ))}
                    </Document>
                </ForwardedPdfContainer>
                <SideContainer width={openSignPerson}>
                    <div className='m-4'>
                        {props.fileSignatureInfo && props.fileSignatureInfo.map((item, index) => (
                            <div key={index}>
                                <ListItem onClick={() => toggleItem(index)}>
                                    {openIndex !== index && <FontAwesomeIcon icon={faCaretRight} />}
                                    {openIndex === index && <FontAwesomeIcon icon={faCaretDown} />}
                                    <b className='ms-2'>REV.{index + 1}: Signed By {item.signerO || 'Unknown'}</b>
                                </ListItem>
                                <ListContent isOpen={openIndex === index}>
                                    <div className='ms-3'>Signature is valid</div>
                                    <div className='ms-4'>Document has not been modified since signature was applied</div>
                                    <div className='ms-3'>Signature Details</div>
                                    <div className='ms-4'>
                                        <div>Signed Date: {item.signedDate}</div>
                                        <div>Signer Email: {item.signerEmail}</div>
                                        <div>Reason: {item.reason}</div>
                                        <div>Issuer Owner: {item.issuerO}</div>
                                        <div>Signer CN: {item.signerCN}</div>
                                    </div>
                                    <br />
                                </ListContent>

                            </div>
                        ))}
                    </div>
                </SideContainer>

            </ViewerContainer>

        </Drawer>
    )
}

export default PDFView