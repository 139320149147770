import React, { useEffect, useContext, useState } from "react";
import "./App.scss";
import '@fortawesome/fontawesome-svg-core/styles.css';
import appConfig from "./appConfig";
import { useNavigate, useLocation, Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BaseAPI } from "@digital-signer-gw/digital-signer-gw-ts-react/dist/base";
import { Route, Routes } from "react-router-dom";
import DigitalSignature from "./page/digitalSignaturePortal/DigitalSignature";
import { ConfigProvider } from "antd";
import Thankyou from "./page/Thankyou";
import OtpForm from "./page/otp/OtpForm";
import Login from "./page/Login";
import { AuthState, authSelector } from "./slice/authSlice";
import MainPage from "./page/mainPage/MainPage";
import ExceptionalPage from "./page/ExceptionalPage";
import { SigningControllerApi } from "@digital-signer-gw/digital-signer-gw-ts-react";
import { messageError } from "./page/share/AlertModal";
import { stat } from "fs";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const App = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const auth: AuthState = useSelector(authSelector);
  const signingControllerApi: SigningControllerApi = new SigningControllerApi();
  //const tokenPamars = searchParams.get("token");
  const query = useQuery();


  useEffect(() => {
    interceptors();
    handleIdToken();
    //handleToken();
  }, []);

  // useEffect(() => {
  //   console.log(tokenPamars)
  //   handleIdToken();
  //   //handleToken();
  // }, [tokenPamars]);

  const handleIdToken = () => {

    let token = query.get("token");
    const viewerToken = query.get("viewerToken");

    if (viewerToken !== null) {
      localStorage.setItem("viewerToken", viewerToken);
      localStorage.removeItem('IdToken');
      localStorage.removeItem('link_type');
      localStorage.removeItem('ds_jwt_token');
      localStorage.setItem("IdToken", viewerToken);
      dispatch({ type: "idToken/setIdToken", payload: token });
      signingControllerApi.getLinkTypeByViewerTokenUsingGET(viewerToken).then((response) => {
        if (response.data.data === "EXTERNAL") {
          localStorage.setItem("link_type", "EXTERNAL")
          navigate('/otp')
        } else {
          localStorage.setItem("link_type", "INTERNAL")
          navigate("/login")
        }

      }).catch((e: any) => {
        messageError(e)
      })
    } else if (token !== null) {
      localStorage.removeItem("viewerToken");
      localStorage.removeItem('IdToken');
      localStorage.removeItem('link_type');
      localStorage.removeItem('ds_jwt_token');
      localStorage.setItem("IdToken", token);
      dispatch({ type: "idToken/setIdToken", payload: token });
      // localStorage.setItem("token", token);
      // dispatch({ type: "token/setToken", payload: searchParams.get("token") });
      signingControllerApi.getLinkTypeUsingGET(token).then((response) => {
        if (response.data.data === "EXTERNAL") {
          localStorage.setItem("link_type", "EXTERNAL")
          navigate('/otp')
        } else {
          localStorage.setItem("link_type", "INTERNAL")
          navigate("/login")
        }

      }).catch((e: any) => {
        messageError(e)
      })
    } else {
      if(pathname.includes("otp") || pathname.includes("login")){
        if (pathname.includes("otp")) {
          localStorage.removeItem('ds_jwt_token');
          navigate('/otp')
        }
        if (pathname.includes("login")) {
          localStorage.removeItem('ds_jwt_token');
          navigate('/login')
        }

      } else if (localStorage.getItem("IdToken") !== null) {
        //set token to redux
        dispatch({
          type: "idToken/setIdToken",
          payload: localStorage.getItem("IdToken"),
        });
        if (!localStorage.getItem('ds_jwt_token')) {
          messageError('Please login again');
        }
        navigate("/digitalSignature");
        // dispatch({
        //   type: "token/setToken",
        //   payload: localStorage.getItem("token"),
        // });
      }
    }
  }

  const handleToken = () => {
    // handle token if not valid alert
    // save token to localstorage browser
    const tokenPamars = searchParams.get("token");
    console.log(pathname);
    if (tokenPamars !== null) {
      //save to localstorage
      localStorage.setItem("token", tokenPamars);
      dispatch({ type: "token/setToken", payload: searchParams.get("token") });
      navigate("/digitalSignature");
    } else {
      //get token from localstorage
      if (localStorage.getItem("token") !== null) {
        //set token to redux
        dispatch({
          type: "token/setToken",
          payload: localStorage.getItem("token"),
        });
      } else {
        if (!pathname.includes("otp")) {
          navigate("/Page404");
        } else {
          navigate("/login")
        }
        //redirect to login

      }
    }
  };

  const handlePath = (path: any) => {
    let namePath = path.split('/api')[1];
    return namePath
  }

  const interceptors = () => {
    const swaggerAxios = new BaseAPI();
    swaggerAxios['axios'].interceptors.request.use((configAxios) => {
      configAxios.url = process.env.REACT_APP_API_BASE_URL + handlePath(configAxios.url);
      if (localStorage.getItem("ds_jwt_token") !== null && !configAxios.url?.includes("otp") && !configAxios.url?.includes("/public/auth/token") && !configAxios.url?.includes("/public/signing/link-type")) {
        configAxios.headers = {
          'Authorization': `Bearer ${localStorage.getItem("ds_jwt_token")}`,
          'Content-Type': 'application/json'
        };
      } else {
        configAxios.headers = { 'Content-Type': 'application/json' };
      }

      // setLoading(true);
      return configAxios;
    })
    swaggerAxios["axios"].interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          const statusCode = error.response?.status;
          const message = error.response?.data?.message || '';
          console.error(error.response);
          if(statusCode){
            error.name = String(statusCode);
          }
          error.message = message;
          if (statusCode === 403) {
            navigate('/Page404')
            return Promise.reject(error.response?.data);
          } else if (statusCode === 400) {
            return Promise.reject(error.response?.data);
          }          
          return Promise.reject(error);
        } else {
          console.error("Error:", error.message);
          //navigate('/login')
          return Promise.reject(error);
        }
      }
    );
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#ff9900', fontFamily: "DB Helvethaica", fontSize: 22 } }}>
      <appConfig.Provider value={{ /* your configuration values */ }}>
        <Routes>
          <Route path="/digitalSignature" element={auth.isLoggedIn ? <DigitalSignature /> : <Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/otp" element={<OtpForm />} />
          <Route path="/Page404" element={<ExceptionalPage />} />
          <Route path="/main" element={<MainPage />} />
          {/* <Route path="/" element={auth.isLoggedIn ? <DigitalSignature /> : <Navigate to="/login" />} /> */}
        </Routes>
      </appConfig.Provider>
    </ConfigProvider>
  );
};

export default App;
