import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components';
import PassphraseInput from './PassphraseInput';
import {messageError} from '../../share/AlertModal';
import {SignPDF, FileUtils} from "@webcertp12/webcertp12signer";
import {Button, Modal, Spin} from 'antd';
import {
    SigningControllerApi,
    SigningInfoResponse,
    VaultSecretControllerApi
} from '@digital-signer-gw/digital-signer-gw-ts-react';
import * as logo from "../../../asset/image/set_logo.png";
import * as keyLogo from "../../../asset/image/key_vertical.png"
import {P12Signer} from '@signpdf/signer-p12';
import signpdf from '@signpdf/signpdf';
import {useNavigate} from 'react-router-dom';
import {b64toBlob} from '../../share/CommonUtill';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {VaultListResponse} from "@digital-signer-gw/digital-signer-gw-ts-react/models/vault-list-response";
// @ts-ignore
import * as forge from '@digital-signer-gw/node-forge';
import {Buffer} from "buffer";

type Props = {
    setStatePage: (value: string) => void
    isAdmin: boolean
    apiInfoData: SigningInfoResponse | undefined;
    pdfFile: any;
    handleDosigning: () => void

}
const DropZone = styled.div<{ isDragging: boolean }>`
    width: 100%;
    height: 200px;
    border: 2px dashed ${({isDragging}) => (isDragging ? '#f6a015' : '#aaa')};
    background-color: ${({isDragging}) => (isDragging ? '#fcdca2' : 'white')};
    border-radius: 5px;
    text-align: center;
    transition: border-color 0.3s ease, background-color 0.3s ease;
`;

const FileUploadButton = styled.button`
    margin-top: 10px;
`;

const SignPage = (props: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [vaultSecretName, setVaultSecretName] = useState<any | null>(null);
    const [vaultList, setVaultList] = useState<VaultListResponse[]>();
    const [passphrase, setPassphrase] = useState("");
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const tokenState = localStorage.getItem("IdToken") as any;
    const signingControllerApi: SigningControllerApi = new SigningControllerApi();
    const vaultSecretControllerApi: VaultSecretControllerApi = new VaultSecretControllerApi();
    const [modalConfirm, setModalConfirm] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (props.isAdmin) {
            vaultSecretControllerApi.getVaultListUsingPOST().then((response) => {
                setVaultList(response.data.data);
                if (response.data.data && response.data.data.length > 0) {
                    setVaultSecretName(response.data.data[0].key)
                }
            }).catch((e) => {
                messageError(e);
            })
        }
    }, [])
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files && event.target.files[0];
        setFile(selectedFile || null);
    };
    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        if (name === "passphrase") {
            setPassphrase(value);
        }
    };
    const handleOk = (e: any) => {
        setModalConfirm(false);
        if (props.isAdmin) {
            handleVaultSigning(e)
        } else {
            handleUploadSubmit(e)
        }
    }
    const openConfirmModal = (e: any) => {
        e.preventDefault();
        if (!props.isAdmin) {
            if (!file) {
                messageError('Please select a certificate file first.')
                return;
            }
        }
        setModalConfirm(true);
    }
    const handleUploadSubmit = async (event: any) => {
        event.preventDefault();
        setSpinning(true);
        if (file) {
            const certBuffer = await FileUtils.fileToBuffer(file);
            try {
                const revokedCert = false;
                // const revokedCert = await FileUtils.checkRevokedCertificate(
                //     certBuffer,
                //     passphrase,
                //     process.env.REACT_APP_API_PROXY_BASE_URL
                // );

                if (!revokedCert) {

                    try {
                        await doSigning(props.apiInfoData);
                    } catch (error: any) {
                        setSpinning(false)
                        messageError("Error signing PDF:" + error.message);
                    }
                }
            } catch (e) {
                setSpinning(false)
                messageError('Incorrect Password')
            }

        } else {
            messageError("Please select a certificate file first.");
            setSpinning(false)
        }
    };

    const convertP12ToPem = async (p12Buffer: Buffer, password: string): Promise<any> => {
        const p12Asn1 = forge.asn1.fromDer(p12Buffer.toString('binary'));

        // Parse the PKCS#12 object
        const p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);

        // Extract certificates and private key
        const bags = p12.getBags({bagType: forge.pki.oids.certBag});
        // @ts-ignore
        const certs = bags[forge.pki.oids.certBag].map((bag: any) => bag.cert);
        const keyBags = p12.getBags({bagType: forge.pki.oids.pkcs8ShroudedKeyBag});
        // @ts-ignore
        const privateKey = keyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0].key;

        // Convert to PEM format
        const certPem = certs.map((cert: forge.pki.Certificate) => forge.pki.certificateToPem(cert)).join('\n');
        // @ts-ignore
        const keyPem = forge.pki.privateKeyToPem(privateKey);

        // Convert PEM strings to Buffers
        return {
            certPem: Buffer.from(certPem),
            keyPem: Buffer.from(keyPem)
        };
    }

    const convertPeMToP12 = async (certPem: Buffer, keyPem: Buffer, password: string) => {
        const cert = forge.pki.certificateFromPem(certPem.toString());
        const privateKey = forge.pki.privateKeyFromPem(keyPem.toString());

        // Create a new PKCS#12 (P12) container
        const p12Asn1 = forge.pkcs12.toPkcs12Asn1(
            privateKey,
            [cert],
            password
        );

        // Convert ASN.1 object to DER and then to Buffer
        const p12Der = forge.asn1.toDer(p12Asn1).getBytes();
        return Buffer.from(p12Der, 'binary');
    }

    const doSigning = async (apiInfoData: any) => {




        try {
            setSpinning(true)
            const response =
                await signingControllerApi.downloadBase64ByTokenUsingGET(
                    tokenState
                );
            const pdfBuffer = await FileUtils.fileToBuffer(b64toBlob(response.data?.data?.data));
            let certBuffer = await FileUtils.fileToBuffer(file);

            const {certPem, keyPem} = await convertP12ToPem(certBuffer, passphrase);
            certBuffer = await convertPeMToP12(certPem, keyPem, passphrase);

            if (
                pdfBuffer &&
                certBuffer
            ) {
                // @ts-ignore
                const p12Signer = new P12Signer(certBuffer, {passphrase: passphrase, asn1StrictParsing: false});
                const signedPdf = await signpdf.sign(pdfBuffer, p12Signer);

                const blob = new Blob([signedPdf], {
                    type: "application/octet-stream",
                });
                if (signedPdf) {
                    // await callApiSave(blob);
                    signingControllerApi.uploadUsingPOSTForm(blob, tokenState).then((res) => {
                        setSpinning(false)
                        props.handleDosigning();

                    }).catch(err => {
                        setSpinning(false)
                        messageError(err);
                    });
                }


            } else {
                messageError("NOT sign");
                setSpinning(false)
            }
        } catch (error: any) {
            console.error(
                `Error signing the file with UUID: ${apiInfoData.signingFile.uuid}. Details:`,
                error
            );
            messageError(`Please Contract Admin Error signing the file with UUID: ${apiInfoData.signingFile.uuid}.\n Details: ${error.message}`);
            setSpinning(false)
        }

    };

    const handleVaultSigning = async (event: any) => {
        event.preventDefault();
        setSpinning(true)
        if (vaultSecretName) {
            try {
                const vaultFile = await vaultSecretControllerApi.downloadSecretUsingPOST(vaultSecretName);
                let certBuffer = await FileUtils.fileToBuffer(b64toBlob(vaultFile.data.data));

                const {certPem, keyPem} = await convertP12ToPem(certBuffer, passphrase);
                certBuffer = await convertPeMToP12(certPem, keyPem, passphrase);
                // const revokedCert = await FileUtils.checkRevokedCertificate(
                //     certBuffer,
                //     passphrase,
                //     process.env.REACT_APP_API_PROXY_BASE_URL
                // );
                const revokedCert = false;

                if (!revokedCert) {


                    try {
                        await doSignWithVault(certBuffer);
                    } catch (error: any) {
                        setSpinning(false);
                        messageError("Error signing PDF:" + error.message);
                    }
                }
            } catch (e) {
                setSpinning(false)
                messageError('Incorrect Password')
            }

        } else {
            setSpinning(false)
            messageError("Please select a certificate first.");
        }
    }
    const doSignWithVault = async (certBuffer: any) => {
        try {
            setSpinning(true)

            const pdf = await signingControllerApi.downloadBase64ByTokenUsingGET(tokenState);

            const pdfBuffer = await FileUtils.fileToBuffer(b64toBlob(pdf.data?.data?.data));
            if (
                pdfBuffer &&
                certBuffer
            ) {
                const p12Signer = new P12Signer(certBuffer, {passphrase: passphrase});
                const signedPdf = await signpdf.sign(pdfBuffer, p12Signer);

                const blob = new Blob([signedPdf], {
                    type: "application/octet-stream",
                });
                if (signedPdf) {
                    // await callApiSave(blob);
                    setSpinning(true);
                    signingControllerApi.uploadUsingPOSTForm(blob, tokenState).then((res) => {
                        setSpinning(false)
                        props.handleDosigning();
                    }).catch(err => {
                        setSpinning(false)
                        messageError(err);
                    });
                }


            } else {
                setSpinning(false)
                messageError("NOT sign");
            }

        } catch (error: any) {
            messageError(error)
            setSpinning(false);
        }

    }
    return (
        <div className="container">
            <button className='btn btn-hover' onClick={() => props.setStatePage("MAIN")}
                    style={{position: 'absolute', right: 0, top: 10}}><FontAwesomeIcon className='my-auto'
                                                                                       icon={faXmark}/></button>
            <br/>
            <br/>
            <h4 className="d-flex justify-content-center"><img src={logo.default} alt="Logo" className="logo"
                                                               style={{width: '40px', height: 'auto'}}/>Digital
                Signature</h4>
            <br/>
            <div style={{paddingInline: '15%'}}>
                <div>ข้อความแจ้งเตือนก่อนการลงลายเซ็นดิจิทัล<br/>

                    โปรดอ่านและตรวจสอบข้อมูลต่อไปนี้อย่างรอบคอบก่อนที่จะทำการลงลายเซ็นดิจิทัล:<br/>
                    <ol>
                        <li>ความรับผิดชอบ: การลงลายเซ็นดิจิทัลของคุณถือเป็นการยืนยันว่าคุณได้อ่าน, ทำความเข้าใจ,
                            และยอมรับเงื่อนไขที่ระบุไว้ในเอกสารหรือข้อตกลงนี้อย่างเต็มที่
                            การลงลายเซ็นเทียบเท่ากับลายเซ็นมือของคุณในเอกสารกระดาษ
                        </li>
                        <li>ความถูกต้องและความเป็นเอกลักษณ์:
                            ลายเซ็นดิจิทัลมีความปลอดภัยสูงและใช้เทคโนโลยีการรับรองเพื่อยืนยันตัวตนของคุณ
                            โปรดให้ความสนใจว่าการใช้ลายเซ็นดิจิทัลไม่สามารถถูกยกเลิกหรือแก้ไขได้หลังจากการยืนยัน
                        </li>
                        <li>ความปลอดภัยข้อมูล:
                            ข้อมูลส่วนบุคคลและลายเซ็นดิจิทัลของคุณจะถูกปกป้องด้วยมาตรฐานความปลอดภัยข้อมูลที่สูง
                            โปรดตรวจสอบว่าคุณอยู่ในสภาพแวดล้อมที่ปลอดภัยก่อนทำการลงลายเซ็น
                        </li>
                        <li>ผลลัพธ์ของการลงลายเซ็น: การลงลายเซ็นดิจิทัลถือว่าคุณยอมรับทุกข้อกำหนดและเงื่อนไข
                            รวมถึงการยืนยันการทำธุรกรรม, การให้สิทธิ์, หรืออนุญาติต่างๆ
                            ตามที่เอกสารหรือข้อตกลงระบุไว้
                        </li>
                    </ol>
                    โปรดยืนยันว่าคุณได้อ่านและเข้าใจข้อความแจ้งเตือนนี้อย่างละเอียดก่อนทำการลงลายเซ็นดิจิทัล
                </div>
                <hr/>
                {!props.isAdmin && <form onSubmit={openConfirmModal}>
                    <DropZone onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}
                              isDragging={isDragging}>
                        <input id="fileInput" name='fileInput' className='d-none' type="file" accept=".p12"
                               onChange={e => handleFileInputChange(e)}/>
                        <div className='mt-5'>
                            <p>Drop your certificate here (P12) or</p>
                            <label className='btn btn-outline-secondary' htmlFor="fileInput">
                                Select File
                            </label>
                        </div>
                    </DropZone>
                    {file && (
                        <div>
                            <p>Certificate: {file.name}</p>
                        </div>
                    )}
                    <PassphraseInput onInputChange={handleInputChange}/>
                    <hr/>
                    <div className="d-flex justify-content-end gap-2">
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            style={{minWidth: 150}}
                            onClick={() => props.setStatePage("MAIN")}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            style={{minWidth: 150}}
                            className="btn btn-primary text-white"

                        >
                            Sign
                        </button>

                    </div>
                </form>}
                {props.isAdmin && <form onSubmit={openConfirmModal}>
                    <div className="d-flex my-3">
                        <label className="col-form-label col-auto me-2">Certificate</label>
                        <select name="vault" id="vault" className='form-select' value={vaultSecretName}
                                onChange={(event) => setVaultSecretName(event.target.value)} required>
                            {vaultList?.map(secret => (
                                <option value={secret.key}>{secret.value}</option>
                            ))}
                        </select>
                    </div>

                    <PassphraseInput onInputChange={handleInputChange}/>
                    <hr/>
                    <div className="d-flex justify-content-end gap-2">
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            style={{minWidth: 150}}
                            onClick={() => props.setStatePage("MAIN")}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            style={{minWidth: 150}}
                            className="btn btn-primary text-white"

                        >
                            Sign
                        </button>

                    </div>
                </form>}
                <br/>
            </div>

            <Spin spinning={spinning} fullscreen/>
            <Modal
                title=" "
                open={modalConfirm}
                onOk={handleOk}
                okText="Confirm"
                cancelText="Cancel"
                onCancel={() => setModalConfirm(false)}
            >
                <br/>
                <div className='d-flex'>

                    <div className='col-2'><img src={keyLogo.default} style={{flex: 1, width: "100%"}}></img></div>
                    <div className='col-8 ms-4'>
                        <h5>ยืนยันการลงนาม</h5>
                        <p>โปรดทราบว่าเมื่อท่านได้ทำการลงนามยืนยันในขั้นตอนนี้แล้ว
                            ท่านจะไม่สามารถดำเนินการแก้ไขข้อมูล
                            ที่ได้บันทึกไว้ได้ในภายหลัง
                            กรุณาตรวจสอบข้อมูลให้ถูกต้องและครบถ้วนก่อนการยืนยันการดำเนินการ
                            เพื่อป้องกันความผิดพลาดใดๆ ที่อาจเกิดขึ้น</p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SignPage
