import React, { useState } from 'react'
import Header from '../share/Header'
import { format } from 'date-fns'
import SideBar from '../share/SideBar'
import { Content, Footer } from '../../asset/style'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import BreadCrumbCustom from '../share/BreadCrumbCustom'

type Props = {}
const Box = styled.div`
    display: flex;
    padding: 10px 15px 10px 15px;
    border: 1px solid #C9C9C9;
    background-color: white;
    border-radius: 10px;
`
const MainPage = (props: Props) => {
    const [date, setDate] = useState<Date>(new Date());
    const navigate = useNavigate();
    const goToDigitalSignature = (e:any) => {
        navigate("/digitalSignature")
    }
    return (
        <div>
            <Header date={format(date, 'yyyy-MM-dd')} signerRole={undefined} />
            <div className='d-flex'>
                <SideBar />
                <div className='d-flex flex-column'>
                    <Content>
                    <BreadCrumbCustom item={[{href:'/main',title:'Home'}]} />
                        <Box>
                            <div>เลขที่สัญญา : SET-CONT-004-2565</div>
                            <div className='ms-auto'><a className='click text-secondary ' onClick={(e)=>goToDigitalSignature(e)}>รายละเอียด</a></div>
                        </Box>
                    </Content>
                </div>

            </div>
        </div>
    )
}

export default MainPage