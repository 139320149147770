import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';


type Props = {
  onInputChange: (value: any) => void
}

const PassphraseInput = (props: Props) => {
  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  return (
    <div className="d-flex my-3">
      <label className="col-form-label col-auto me-2">Password</label>
      <div className="input-group mb-3">
        <input type={changePassword ? "password" : "text"} name="passphrase" className="form-control" onChange={props.onInputChange} required />

        <button onClick={() => {
          setChangePassword(changeIcon);
        }} className="btn btn-outline-secondary" type='button' id="basic-addon2">{changeIcon ? <FontAwesomeIcon style={{verticalAlign:'middle'}} icon={faEye} /> : <FontAwesomeIcon style={{verticalAlign:'middle'}} icon={faEyeSlash} />}</button>

      </div>


    </div>
  );
}

export default PassphraseInput