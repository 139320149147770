import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { spaceChildren } from 'antd/es/button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    item: ItemType[]
}

const BreadCrumbCustom = (props: Props) => {
    const navigate = useNavigate();
  return (
    <div className='d-flex'>
        {props.item && props.item.map((x,index)=>(
            <div className='d-flex' key={"BreadCrumd-"+index}>
                {x.href && <a style={{fontSize:20}} className='link-blue' onClick={()=>navigate(x.href as any)}>{x.title}</a>}
                {!x.href && <span style={{fontSize:20}} >{x.title}</span>}
                {index != props.item.length-1 && <span style={{fontSize:20}} className='mx-1'>/</span>}
            </div>
        )) }
        <div className='mb-3'></div>
    </div>
  )
}

export default BreadCrumbCustom