import React, { useState } from 'react'
import * as logo from "../../asset/image/set_logo.png";
import styled from 'styled-components';
import { UserInfo } from '@digital-signer-gw/digital-signer-gw-ts-react';
import { useSelector } from 'react-redux';
type Props = {
  date: string | undefined | null;
  signerRole: string | undefined | null;
}
const HeaderContainer = styled.div`
  border-bottom: 4px solid #F6A015;
`
const Header = (props: Props) => {
  const userProfile = useSelector((state:any) => state.userProfileReducer) as UserInfo;
  return (
    <HeaderContainer className='d-flex p-1'>
      <div className='col my-auto'> <h5 className='ms-5 my-auto'><img src={logo.default} alt="Logo" className="logo" style={{ width: '40px', height: 'auto' }} />SET Digital signature</h5></div>
      <div className='col d-flex justify-content-end my-auto me-3'>
        <div className='d-flex flex-column' style={{lineHeight:1.2}}>
          <div>ยินดีต้อนรับ {userProfile?.username}</div>
          <div><small className='text-secondary'>{props.signerRole}</small></div>
        </div>
        <div><img src="/images/user-circle-thin-svgrepo-com.svg" alt="" style={{width:50,height:'auto',marginLeft:10}} /></div>
      </div>
    </HeaderContainer>
  )
}

export default Header