import { createSlice } from '@reduxjs/toolkit'

export const idTokenSlice = createSlice({
  name: 'idToken',
  initialState: null,
  reducers: {
    setIdToken: (state, action) => action.payload
  },
})

// Action creators are generated for each case reducer function
export const { setIdToken } = idTokenSlice.actions

export default idTokenSlice.reducer