import { Modal } from 'antd'
import React from 'react'
import * as logo from "../../asset/image/set_logo.png";
type Props = {
    open: boolean
    body: string
}

const ThankyouModal = (props: Props) => {
    return (
        <div>
            <Modal
                open={props.open}
                title=" "
                footer={null}
                closable={false}
                centered
            >
                <div className='text-center'>
                    <div className='d-flex justify-content-center'>
                    <img src={logo.default} alt="Logo" className="logo"  />
                    <h4>Digital Signature</h4>
                    </div>
                    <br />
                    <span>{props.body}</span>
                    <br />
                    <br />
                    <br />
                </div>
            </Modal>
        </div>
    )
}

export default ThankyouModal