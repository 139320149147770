import { UserInfo } from '@digital-signer-gw/digital-signer-gw-ts-react'
import { createSlice } from '@reduxjs/toolkit'
const userProfile = window.localStorage.getItem("ds_userProfile") ? JSON.parse(window.localStorage.getItem("ds_userProfile") as string) : undefined;
const initialState: UserInfo = userProfile ? {
    username: userProfile.username,
    email: userProfile.email,
    userType: userProfile.userType
} : {
    username: undefined,
    email: undefined,
    userType: undefined
}

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: initialState,
    reducers: {
        setProfle: (state, action) => {
            if(action.payload){
                window.localStorage.setItem('ds_userProfile', JSON.stringify(action.payload.data))
                state.username = action.payload?.data.username;
                state.email = action.payload?.data.email;
                state.userType = action.payload?.data.userType;
            }
           
        }

    },
})

// Action creators are generated for each case reducer function
export const { setProfle } = userProfileSlice.actions

export default userProfileSlice.reducer