
// import { EmployeeProfile, UserProfileControllerApi, KeycloakAuthenticationControllerApi, GetTokenRequest, KeycloakToken, RefreshTokenRequest } from '@km-gw/tsd-km-ts-react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AuthBodyRequest, AuthControllerApi, OtpControllerApi } from '@digital-signer-gw/digital-signer-gw-ts-react';
export interface AuthState {
    loading: boolean
    isLoggedIn: boolean
    token: any | undefined;
}
const name = "authentication";
const token = window.localStorage.getItem("ds_jwt_token") ? window.localStorage.getItem("ds_jwt_token") : undefined;
const initialState: AuthState = token ? {
    loading: false,
    isLoggedIn: true,
    token: token
} : {
    loading: false,
    isLoggedIn: false,
    token: undefined,
}
const authSlice = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // builder.addCase(getToken.pending,(state,{payload})=>{
        //     state.loading=true;
        // })
        // builder.addCase(getToken.fulfilled,(state,{payload})=>{
        //     window.localStorage.setItem('token',JSON.stringify(payload))
        //     state.isLoggedIn = true;
        //     state.token = payload;
        //     state.loading=false;
        // })
        // builder.addCase(getToken.rejected,(state)=>{
        //     state.isLoggedIn = false;
        //     state.token = undefined;
        //     state.loading=false;
        // });
        // builder.addCase(refreshToken.pending,(state,{payload})=>{
        //     state.loading=true;
        // })
        // builder.addCase(refreshToken.fulfilled,(state,{payload})=>{
        //     window.localStorage.setItem('token',JSON.stringify(payload))
        //     state.isLoggedIn = true;
        //     state.token = payload;
        //     state.loading=false;
        // })
        // builder.addCase(refreshToken.rejected,(state)=>{
        //     state.isLoggedIn = false;
        //     state.token = undefined;
        //     state.loading=false;
        // })
        builder.addCase(loginByOTP.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(loginByOTP.fulfilled, (state, { payload }) => {
            state.loading = true;
            let token = payload.data?.token as any
            window.localStorage.setItem('ds_jwt_token', token)
            state.isLoggedIn = true;
            state.token = payload;
            state.loading = false;
        })
        builder.addCase(loginByOTP.rejected, (state) => {
            state.loading = true;
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.isLoggedIn = false;
            state.token = undefined;
        })
        builder.addCase(loginByKeyCloak.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(loginByKeyCloak.fulfilled, (state, { payload }) => {
            state.loading = true;
            let token = payload.data?.token as any
            window.localStorage.setItem('ds_jwt_token', token)
            state.isLoggedIn = true;
            state.token = payload;
            state.loading = false;
        })
        builder.addCase(loginByKeyCloak.rejected, (state) => {
            state.loading = true;
        })
        
    }
})

// const keycloakAuthenticationControllerApi: KeycloakAuthenticationControllerApi = new KeycloakAuthenticationControllerApi();
// export const refreshToken = createAsyncThunk(
//     "authentication/refreshToken",
//     async (body:RefreshTokenRequest) => {
//         const res = await keycloakAuthenticationControllerApi.refreshTokenUsingPOST(body);
//         return res.data;
//     }
// );

// export const getToken = createAsyncThunk(
//     "authentication/getToken",
//     async (body: GetTokenRequest) => {
//         const res = await keycloakAuthenticationControllerApi.getTokenUsingPOST(body);
//         return res.data;
//     }
// );

const otpControllerApi: OtpControllerApi = new OtpControllerApi();
const authControllerApi: AuthControllerApi = new AuthControllerApi();
export interface RequestLoginOTP {
    enterOTP: string;
    idToken: string;
}
export const loginByOTP = createAsyncThunk(
    "authentication/loginByOTP",
    async (body: RequestLoginOTP) => {
        const res = await otpControllerApi.verifyOtpUsingPOST(body.enterOTP, body.idToken);
        return res.data;
    }
);


export const loginByOTPViewerToken = createAsyncThunk(
    "authentication/loginByOTP",
    async (body: RequestLoginOTP) => {
        const res = await otpControllerApi.verifyOtpLinkViewerUsingPOST(body.enterOTP, body.idToken);
        return res.data;
    }
);

export const loginByKeyCloak = createAsyncThunk(
    "authentication/loginByKeyCloak",
    async (body: AuthBodyRequest) => {
        const res = await authControllerApi.getKeycloakTokenUsingPOST(body);
        return res.data;
    }
)



export const logout = createAsyncThunk("authentication/logout", async () => {
    await window.localStorage.removeItem("ds_jwt_token");
});

export const authSelector = (store: RootState) => store.authReducer;
export default authSlice.reducer

